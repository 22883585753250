import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons"
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons"

const getStarRates = rate => {
  switch (rate) {
    case 5:
      return (
        <>
          <ul className="flex justify-center md:gap-4 gap-1">
            <li>
              <FontAwesomeIcon
                icon={faStar}
                className="text-case_orange md:text-3xl text-xl"
              />
            </li>
            <li>
              <FontAwesomeIcon
                icon={faStar}
                className="text-case_orange md:text-3xl text-xl"
              />
            </li>
            <li>
              <FontAwesomeIcon
                icon={faStar}
                className="text-case_orange md:text-3xl text-xl"
              />
            </li>
            <li>
              <FontAwesomeIcon
                icon={faStar}
                className="text-case_orange md:text-3xl text-xl"
              />
            </li>
            <li>
              <FontAwesomeIcon
                icon={faStar}
                className="text-case_orange md:text-3xl text-xl"
              />
            </li>
          </ul>
        </>
      )
    case 4:
      return (
        <ul className="flex justify-center md:gap-4 gap-1">
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={regularStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
        </ul>
      )
    case 3:
      return (
        <ul className="flex justify-center md:gap-4 gap-1">
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={regularStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={regularStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
        </ul>
      )
    case 2:
      return (
        <ul className="flex justify-center md:gap-4 gap-1">
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={regularStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={regularStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={regularStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
        </ul>
      )
    case 3.5:
      return (
        <ul className="flex justify-center md:gap-4 gap-1">
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={faStarHalfAlt}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={regularStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={regularStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
        </ul>
      )
    case 2.5:
      return (
        <ul className="flex justify-center md:gap-4 gap-1">
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={faStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={faStarHalfAlt}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={regularStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={regularStar}
              className="text-case_orange md:text-3xl text-xl"
            />
          </li>
        </ul>
      )
    default:
      break
  }
}

export const StarRates = ({ rate }) => <>{getStarRates(rate)}</>
