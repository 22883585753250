import React from "react"

export const FlowNumber = ({
  num,
  parklot,
  title,
  last = false,
  diagram = false,
  children,
}) => {
  return (
    <div
      className={`${
        parklot ? "flow-balloon balloon-accent" : "flow-balloon balloon-primary"
      } relative flow-balloon mb-6 ${last ? "flow-balloon balloon-last" : ""}`}
    >
      <div
        className={`${
          parklot ? "bg-accent_pale" : "bg-secondary"
        } flex md:items-center w-full`}
      >
        <div
          className={`relative py-2 balloon-r flex items-center justify-center text-center ${
            parklot ? "bg-accent right-accent" : "bg-primary right-primary"
          }`}
        >
          <div>
            <p className="text-white font-mont font-bold text-4xl px-5">
              {num}
            </p>
            <p className="text-white px-2 text-xs">
              {parklot ? "PARKLoT" : "ご担当者様"}
            </p>
          </div>
        </div>
        <h3
          className={`text-xl md:ml-8 ml-5 font-bold pr-4 md:pt-0 pt-2 leading-tight md:leading-normal ${
            parklot ? "text-accent" : "text-primary"
          }`}
        >
          {title}
        </h3>
      </div>
      {/* 図や写真が入る時はdiagram=trueにするとフレックスになる */}
      <div
        className={`${parklot ? "bg-accent_pale" : "bg-secondary"} ${
          diagram ? "flex md:flex-row flex-col gap-4" : ""
        } p-4`}
      >
        {children}
      </div>
    </div>
  )
}
