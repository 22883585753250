import React from "react"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CvBtn } from "components/case_study/cv-btn"
import { StarRates } from "components/case_study/star-rates"
import { FlowNumber } from "components/case_study/flow-number"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
config.autoAddCss = false

// キャンペーン概要
const features = [
  { color: "bg-case_blue", title: "Diffusivity", content: "Medium" },
  {
    color: "bg-case_orange",
    title: "Lottery Method",
    content: "Drawn at a later date",
  },
  { color: "bg-accent", title: "Result Confirmation Method", content: "DM" },
]

// こんな方におすすめ文言
const recommends = [
  { title: "Low budget for holding campaigns" },
  { title: "Want to buzz the campaign itself." },
  { title: "Want to further increase my company's visibility" },
]

const TwitterFollowAndQuoteTweet = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="Twitter Campaign Case Study, Quoted Retweet with Hashtag"
          pagepath={location.pathname}
          pagedesc="You can enter the campaign by following the campaign sponsor's Twitter account and retweeting campaign tweets with the specified hashtag. Although less diffuse than a follow and retweet campaign, it can build relationships with followers and gain fans."
        />
        <main className="case_study main">
          {/* メインビジュアル */}
          <section className="hero h-auto">
            <div className="inner relative py-5 md:py-8 px-4">
              <h1 className="text-white font-bold md:mb-4 mb-3">
                Follow &amp;&nbsp;
                <br className="block md:hidden" />
                hashtag quote retweet
              </h1>
              <p className="text-white md:text-xl text-lg font-bold leading-relaxed">
                To enter the campaign, simply follow the event's account&nbsp;
                <br className="md:block hidden" />
                and retweet the campaign tweet with the specified hashtag.
                <br className="md:block hidden" />
                Although less diffuse than the Follow &amp;&nbsp;Retweet
                campaign,&nbsp;
                <br className="md:block hidden" />
                this campaign can build relationships with followers and gain
                fans.
              </p>
              <div className="fv_img absolute -right-8 top-32">
                <StaticImage
                  layout="fullWidth"
                  alt="Follow & hashtag quote retweet"
                  src="../../../../images/case_study/mv_follow_and_quote_tweet.png"
                />
              </div>
            </div>
          </section>
          {/* こんな方におすすめです */}
          <section className="recommend bg-secondary">
            <div className="inner py-6 md:pt-10 md:pb-28 px-4">
              <ul className="flex md:mb-8 mb-6 gap-2">
                {features.map((feature, index) => {
                  return (
                    <li key={index} className="text-center font-bold">
                      <p
                        className={`text-white ${feature.color} py-1 md:px-6 px-1 text-sm md:text-xl`}
                      >
                        {feature.title}
                      </p>
                      <p className="text-sm md:text-xl bg-white py-1 md:px-6 px-3">
                        {feature.content}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <h2 className="text-2xl md:text-3xl text-primary font-bold pb-2 border-b-2 border-primary md:w-3/4 mb-3">
                Recommended for
              </h2>
              <div className="">
                {recommends.map((recommend, index) => {
                  return (
                    <div key={index} className="flex items-center mb-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary mr-3"
                      />
                      <p className="font-bold text-text md:text-xl">
                        {recommend.title}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          {/* キャンペーンの特徴 */}
          <section className="campaign-feat bg-white">
            <div className="inner md:py-20 px-4 pt-8 pb-10">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                Campaign Features
              </h2>
              <div className="flex md:flex-row flex-col md:gap-10 gap-4">
                <div className="flex gap-4 flex-1">
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-case_blue text-white text-center text-sm md:text-base">
                      Participant
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">
                        Diffusivity
                      </p>
                      <StarRates rate={3} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        Ease of participation
                      </p>
                      <StarRates rate={4} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        Motivation
                      </p>
                      <StarRates rate={2.5} />
                    </div>
                  </div>
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-primary text-white text-center text-sm md:text-base">
                      Contact person
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">
                        Easiness
                      </p>
                      <StarRates rate={4} />
                    </div>
                  </div>
                </div>
                <p className="flex-1 text-text">
                  Campaigns vary greatly in the degree of diffusion depending on
                  the nature of the campaign.
                  <br />
                  If the campaign itself becomes a hot topic, it can
                  significantly increase the number of followers.
                  <br />
                  <br />
                  The hurdle for users to participate is higher compared to the
                  Follow &amp; Retweet campaign, as participation is by
                  following and quoting retweets with hashtags.
                  <br />
                  In addition, users are moderately motivated to participate
                  because they are not immediately notified of the results.
                  <br />
                  <br />
                  During the campaign, there will be no work for the person in
                  charge, but after the campaign is over, the participants'
                  profiles will need to be individually verified and DMs will
                  need to be sent, which will require a moderate amount of work
                  for the person in charge.
                  <br />
                  If you wish to send DMs only to the winners, you can do so
                  without using the campaign tool.
                </p>
              </div>
            </div>
          </section>
          {/* 弊社でのキャンペーン実績 */}
          {/* <section className="campaign_results">
            <h2 className="title font-semibold text-center">弊社でのキャンペーン実績</h2>
            <div className="inner">
              <div className="left bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
              <div className="right bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
            </div>
          </section> */}
          {/* PARKLoTのキャンペーンツールを導入するとどう変わる？ */}
          <section className="before_after bg-secondary">
            <div className="inner pt-8 pb-10 md:py-20 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                How would implementing&nbsp;
                <br />
                PARKLoT's campaign tools&nbsp;
                <br />
                change things?
              </h2>
              <p className="mb-6 text-text">
                PARKLoT's campaign tools can significantly reduce operational
                time!
                <br />
                Check the campaign flow to see where reductions can be made.
              </p>
              <div className="flex flex-col md:flex-row md:gap-10 gap-4">
                <div className="flex-1 border border-primary rounded bg-white">
                  <h3 className="bg-primary font-bold text-xl leading-normal text-center text-white py-3">
                    If the campaign is conducted manually
                  </h3>
                  <div className="rounded-b p-4">
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      Open the corresponding campaign page from Twitter
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      List of participants
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue">
                      <div className="flex justify-center items-center mb-2">
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          Check user details screen
                        </div>
                        <div className="mx-2">
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="text-lightgrey text-base"
                          />
                        </div>
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          Determination of winners
                        </div>
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        Repeat for as many winners as there are winners
                      </p>
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue mb-4 text-center">
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3">
                        Search Winners' Accounts
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3 mb-2">
                        Creating, confirming, and sending DMs
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        Repeat for as many winners as there are winners
                      </p>
                    </div>
                    <h3 className="text-center font-bold text-2xl text-accent mb-3">
                      3-5 hours required
                      <br />
                      <span className="font-bold text-text text-xl">
                        just to complete the campaign.
                      </span>
                    </h3>
                    <ul className="text-xs list-disc leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                      <li>
                        Need to log in to Twitter and&nbsp;
                        <span className="font-bold">
                          check each participant's profile one by one.
                        </span>
                      </li>
                      <li>
                        Need to&nbsp;
                        <span className="font-bold">
                          send DMs to winners one by one.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="border border-accent rounded md:mb-10 mb-4">
                    <h3 className="font-bold text-xl leading-normal bg-accent text-center text-white py-3">
                      When using PARKLoT
                    </h3>
                    <div className="bg-white rounded-b p-4">
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        Winners are determined from CSV created by PARKLoT
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        Create CSV of list of winners
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center text-sm font-bold text-accent bg-accent_pale rounded leading-normal py-4 mb-4">
                        PARKLoT sends bulk DMs to winners
                      </div>
                      <h3 className="text-center text-text font-bold mb-3">
                        The campaign will be done&nbsp;
                        <br />
                        <span className="text-2xl text-accent leading-normal">
                          in 20-30 minutes!
                        </span>
                      </h3>
                      <ul className="text-xs list-disc text-text leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                        <li>
                          <span className="font-bold">
                            Sort, filter, and view the list of participants in
                            CSV format.
                          </span>
                        </li>
                        <li>
                          <span className="font-bold">
                            You can ask PARKLoT to send bulk DMs
                          </span>
                          &nbsp;of winning and losing candidates.
                        </li>
                        <li>
                          (As a secondary effect)
                          <span className="font-bold">
                            Analyze applicant trends and use them in future
                            campaigns
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bottom border border-lightgrey rounded">
                    <h3 className="text-xl font-bold bg-lightgrey leading-normal text-center text-white py-3">
                      The tool is not recommended&nbsp;
                      <br />
                      in the following cases
                    </h3>
                    <div className="bg-white rounded-b py-4 pr-4 pl-8">
                      <ul className="font-bold text-text list-disc leading-normal md:p-4 p-1">
                        <li>No applicant information required</li>
                        <li>Fewer winners</li>
                        <li>No cost to implement the tool</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
          {/* PARKLoTを使用した際の具体的なキャンペーン手順について */}
          <section className="usage_flow bg-white">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                Specific campaign procedures
                <br />
                when using PARKLoT
              </h2>
              <p className="mb-6 text-text">
                Now let me explain in detail the campaign procedure when using
                PARKLoT!
              </p>
              <FlowNumber
                num="01"
                parklot={false}
                diagram={true}
                title="Please provide PARKLoT with your campaign information"
              >
                <p className="text-text">
                  Please provide us with the following information about your
                  campaign at least 3 business days prior to the start of the
                  campaign
                  <br />
                  We will set up the data.
                </p>
                <div className="md:w-3/5 bg-white rounded py-4 px-4">
                  <h4 className="font-semibold text-center mb-3">
                    Information to be provided
                  </h4>
                  <ul className="text-xs leading-normal list-disc md:p-4 p-1">
                    <li>Organizer's Twitter account name</li>
                    <li>Start date and time</li>
                    <li>End date and time</li>
                  </ul>
                </div>
              </FlowNumber>

              <FlowNumber
                num="02"
                parklot={false}
                title="Please post your campaign tweets on Twitter"
              >
                <p className="text-text">
                  Post a tweet about the campaign on Twitter.
                </p>
              </FlowNumber>

              <FlowNumber
                num="03"
                parklot={true}
                title="Start collecting quotes and retweets"
              >
                <p className="text-text">
                  We will start collecting users who quote and retweet the
                  campaign tweets.
                </p>
              </FlowNumber>

              <FlowNumber
                num="04"
                parklot={true}
                title="We will send a CSV of the list of applicants to the person in charge"
              >
                <p className="text-text">
                  We will provide you with a CSV of the list of participants in
                  the campaign.
                  <br />
                  Please check the CSV to determine the winners.
                </p>
              </FlowNumber>

              <FlowNumber
                num="05"
                parklot={false}
                diagram={true}
                title="Please determine the winners and give them to PARKLoT in CSV format"
              >
                <p className="text-text">
                  After the winners have been selected, we will ask you to
                  create a CSV file necessary for us to contact the winners.
                  <br />
                  The text of the winning DM will also be shared with us.
                  <br />
                  <br />
                  When you send us your CSV, please also send us the text of the
                  winning text to be sent via DM.
                </p>
                <div>
                  <div className="bg-white rounded py-4 px-4">
                    <h4 className="font-semibold text-center mb-3">
                      Information to be provided
                    </h4>
                    <ul className="text-xs leading-normal list-disc md:p-4 p-1">
                      <li>User ID</li>
                      <li>User name</li>
                      <li>Account name</li>
                      <li>Prize name</li>
                    </ul>
                  </div>
                  <p className="text-text">(Example)</p>
                  <StaticImage
                    layout="constrained"
                    alt="Information to be provided"
                    src="../../../../images/case_study/winners_csv.png"
                  />
                </div>
              </FlowNumber>

              <FlowNumber
                num="06"
                parklot={true}
                diagram={true}
                last={true}
                title="We will send a batch of DMs to the winners"
              >
                <p className="text-text">
                  We will send a batch of DMs to each winner based on the
                  winning text and CSV sent by the person in charge.
                </p>
                <div>
                  <StaticImage
                    layout="constrained"
                    alt="DM to winners"
                    src="../../../../images/case_study/usage_flow05.png"
                  />
                </div>
              </FlowNumber>
            </div>
          </section>
          {/* PARKLoTの月額料金に含まれる機能は？ */}
          <section className="parklot_function bg-secondary">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                What features are included&nbsp;
                <br />
                in PARKLoT's monthly fee?
              </h2>
              <p className="text-text md:mb-10 mb-5">
                General follow &amp; quote retweet campaigns with hashtags are
                available for a monthly fee.
              </p>
              <h3 className="text-lg text-text font-bold text-center md:mb-6 mb-4">
                Features included in the monthly fee
              </h3>
              <div className="flex md:flex-row flex-col gap-4 md:gap-10 place-content-center">
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="Instant Win Campaign Implementation Costs"
                    src="../../../../images/case_study/parklot_function01.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    Retrieving hashtagged quoted retweets
                  </h4>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="CSV output of participant information"
                    src="../../../../images/case_study/parklot_function02.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text mb-1">
                    Provision of CSV of list of campaign participants
                  </h4>
                  <p className="text-text text-xs">
                    *If complex customization is required,&nbsp;
                    <br />
                    an additional fee may be charged.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
        </main>
      </Layout>
    </>
  )
}

export default TwitterFollowAndQuoteTweet
